import React, { ReactNode } from 'react';
import { Typography, Button } from '@mui/material';

interface CardFullProps {
  title: string;
  price: string;
  originalPrice: string;  // Añadimos esta prop para el precio original
  year: string;
  description: string;
  button: string;
  buttonDemo: string;
  downloadUrl?: string;
  children?: ReactNode;
}

const CardFull: React.FC<CardFullProps> = ({
  title,
  price,
  originalPrice,  // Añadimos esta prop
  year,
  description,
  button,
  downloadUrl,
  children
}) => {
  return (
    <article className="card-full" style={{ position: 'relative' }}>
      
      {/* Descuento en la parte superior */}
      <div style={{
        position: 'absolute',
        width: '100%',
        top: '0px',
        right: '0px',
        backgroundColor: '#00E2B1', // Color de fondo similar al de la imagen
        color: 'black',
        padding: '8px 10px',
        boxSizing: 'border-box',
        borderRadius: '18px 18px 0 0',
        fontWeight: 'bold',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center'
      }}>
        {/* Icono de etiqueta */}
        <span role="img" aria-label="discount" style={{ marginRight: '5px' }}>🏷️</span>
        20% OFF
      </div>

      <div className="card-info">
        <Typography variant="h4">{title}</Typography>

        {/* Mostrar el precio original tachado y el nuevo precio */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" color="primary">
            €{price}
          </Typography>
          <Typography
            variant="body1"
            style={{ textDecoration: 'line-through', marginLeft: '8px' }}  // Precio original tachado
          >
            €{originalPrice}
          </Typography>
        </div>

        <Typography variant="h6">{year}</Typography>
        {children}
        <Typography margin="20px 0 34px 0">
          {description}
        </Typography>
        <div className="flex-start-row-mobile">
          <Button
            variant="contained"
            size="large"
            disableElevation
            className="color btn-generic"
            href={downloadUrl || ''} // Usar downloadUrl como href
            download // Agregar atributo download para iniciar la descarga
          >
            {button}
          </Button>
        </div>
      </div>
    </article>
  );
};

export default CardFull;
